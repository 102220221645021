import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import application from './modules/application.js';

export default createStore({
    modules: {
        application,
    },
    plugins: [createPersistedState({storage: window.sessionStorage, key: 'dsector'})],
});
