<template>
    <header :class="{'scroll': isScrolled}">
        <CenterLayout>
            <div class="menu-wrapper">
                <router-link :to="{name: $ROUTE_NAMES.HOME_VIEW}"
                             class="logo">
                    <img :src="$filters.getResourcePath(resources.COMMON.LOGO)"
                         alt="logo">
                </router-link>
                <ul class="menu">
                    <li v-for="menu in menuList"
                        :key="menu.name"
                        :class="{'on': menu.routeName === routeName}">
                        <router-link :to="{name: menu.routeName}">{{ menu.name }}</router-link>
                        <GlobalSubMenu v-if="menu.menuList"
                                       class="sub"
                                       :menuList="menu.menuList"
                                       :subId="subId"/>
                    </li>
                </ul>
            </div>
            <template #right>
                <ul class="i18n">
                    <li :class="{'on': lang === 'KR'}">
                        <a href=""
                           class="typo_body3"
                           @click.prevent="setLang('KR')">{{ resources.COMMON.$LANG.KR }}</a>
                    </li>
                    <li :class="{'on': lang === 'EN'}">
                        <a href=""
                           class="typo_body3"
                           @click.prevent="setLang('EN')">{{ resources.COMMON.$LANG.EN }}</a>
                    </li>
                </ul>
            </template>
        </CenterLayout>
        <div v-show="isShowMenu"
             class="dimmed"
             @click.prevent="toggleMenu"></div>
        <div v-show="isShowMenu"
             class="mobile-menu">
            <ul>
                <li v-for="menu in menuList"
                    :key="menu.name"
                    :class="{'on': menu.routeName === routeName}">
                    <router-link class="typo_head1"
                                 :to="{name: menu.routeName}">{{ menu.name }}
                    </router-link>
                </li>
            </ul>
            <div class="mobile-i18n">
                <a href=""
                   class="typo_body1"
                   :class="{'on': lang === 'KR'}"
                   @click.prevent="setLang('KR')">{{ resources.COMMON.$LANG.KR }}</a>
                <a href=""
                   class="typo_body1"
                   :class="{'on': lang === 'EN'}"
                   @click.prevent="setLang('EN')">{{ resources.COMMON.$LANG.EN }}</a>
            </div>
        </div>
        <a href=""
           class="menu-icon"
           :style="{'z-index': isShowMenu ? 102 : 101}"
           @click.prevent="toggleMenu">
            <img v-show="isShowMenu"
                 src="@/assets/imgs/icn-close.svg" alt="close">
            <img v-show="!isShowMenu"
                 src="@/assets/imgs/icn-menu.svg" alt="menu">
        </a>
    </header>
</template>

<script>
import {computed, ref} from 'vue';
import CenterLayout from '@/features/components/CenterLayout.vue';
import useWindowEvent from '@/features/compositions/useWindowEvent.js';
import {ROUTE_NAMES} from '@/router';
import {useStore} from 'vuex';
import {APPLICATION_SET_LANG} from '@/store/modules/application.js';
import GlobalSubMenu from '@/features/components/GlobalSubMenu.vue';

export default {
    name: 'GlobalHeader',
    components: {GlobalSubMenu, CenterLayout},
    props: {
        routeName: {
            type: String,
        },
        subId: {
            type: String,
        },
    },
    setup() {
        const store = useStore();

        const resources = computed(() => store.getters.resources);
        const lang = computed(() => store.getters.lang);

        const menuList = computed(() => [
            {name: resources.value.COMMON.$MENU.HOME, routeName: ROUTE_NAMES.HOME_VIEW},
            {name: resources.value.COMMON.$MENU.ABOUT, routeName: ROUTE_NAMES.ABOUT_VIEW},
            {
                name: resources.value.COMMON.$MENU.WORKS,
                routeName: ROUTE_NAMES.WORKS_VIEW,
                menuList: resources.value.WORKS.WORK_LIST.map(work => ({
                    id: work.$KEY,
                    name: work.NAME,
                    routeName: ROUTE_NAMES.WORKS_DETAIL_VIEW,
                    params: {
                        workId: work.$KEY,
                    },
                })),
            },
            {name: resources.value.COMMON.$MENU.LAB, routeName: ROUTE_NAMES.LAB_VIEW},
            {name: resources.value.COMMON.$MENU.BOARD, routeName: ROUTE_NAMES.BOARD_VIEW},
            {name: resources.value.COMMON.$MENU.RECRUIT, routeName: ROUTE_NAMES.RECRUIT_VIEW},
            {name: resources.value.COMMON.$MENU.CONTACT, routeName: ROUTE_NAMES.CONTACT_VIEW},
        ]);

        useWindowEvent('scroll', setScroll);
        useWindowEvent('touchmove', setScroll);

        const isScrolled = ref(false);
        const isShowMenu = ref(false);

        function setScroll() {
            isScrolled.value = window.scrollY > 50;
        }

        function toggleMenu() {
            isShowMenu.value = !isShowMenu.value;
        }

        function setLang(lang) {
            store.dispatch(APPLICATION_SET_LANG, lang);
        }

        return {
            menuList,
            resources,
            lang,
            isScrolled,
            isShowMenu,
            toggleMenu,
            setLang,
        };
    },
};
</script>

<style lang="scss" scoped>
header {
    position: fixed;
    width: 100%;
    height: 88px;
    z-index: 100;
    -webkit-transition: top .5s;
    transition: top .5s;

    .menu-wrapper {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;

        a.logo {
            display: block;
            width: 199px;
            padding: 27px 0;

            img {
                width: 100%;
            }
        }
    }

    &.top:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-filter: blur(50px);
        filter: blur(50px);
        background-color: rgba(0, 0, 0, 0.7);
        z-index: -1;
    }

    &.scroll {
        background-color: rgba(0, 0, 0, 0.3);
    }

    &.show {
        top: 0;
    }

    &.hide {
        top: -88px;
    }
}

ul.menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 3%;
    width: 100%;
    height: 100%;

    li {
        position: relative;

        a {
            color: #aaafb9;
            padding: 35px 0;
            font-weight: 400;
        }

        .sub {
            display: none;
        }

        &:hover {
            .sub {
                display: flex;
            }
        }

        &.on, &:hover {
            a {
                color: #ffffff;
                text-shadow: .25px 0 .1px, -.25px 0 .1px;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -6px;
                left: -4px;
                right: -4px;
                border-bottom: 2px solid #ffffff;
            }
        }
    }
}

ul.i18n {
    position: absolute;
    right: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    li {
        margin-left: 20px;

        a {
            color: #e9ebee;
        }

        ul {
            display: none;
        }

        &.on {
            a {
                color: #fff;
                text-decoration: underline;
            }
        }

        & + li::after {
            content: "";
            position: absolute;
            height: 15px;
            top: 37px;
            left: 47px;
            border-right: 1px solid #e9ebee;
        }
    }
}

.menu-icon {
    display: none;
}

.dimmed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.mobile-menu {
    position: fixed;
    top: 0;
    right: -10000px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 102;
}

@include tablet {
    header {
        height: 88px;

        .menu-wrapper {
            a.logo {
                width: 199px;
            }
        }

        &.top::before {
            content: none;
        }
    }

    ul.menu, ul.i18n {
        display: none;
    }

    .menu-icon {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 88px;
        height: 88px;
        text-align: center;
        padding: 27px 0;

        img {
            width: 38px;
        }
    }

    .mobile-menu {
        right: 0;
        padding-left: 5%;
        padding-top: 100px;

        li {
            margin-top: 24px;
            font-weight: 200;

            a {
                display: inline-block;
                width: 100%;
                color: #898f94;
            }

            &.on, &:hover {
                a {
                    color: #fff;
                    font-weight: 800;
                }
            }
        }

        .mobile-i18n {
            padding-bottom: 60px;

            a {
                display: inline-block;
                padding: 3px 18px;
                text-align: center;
                color: #898f94;

                &.on {
                    color: #fff;
                    background-color: rgba(255, 255, 255, 0.15);
                    font-weight: bold;
                }
            }
        }
    }
}


@include mobile {
    header {
        height: 48px;

        .menu-wrapper {
            a.logo {
                width: 117px;
                padding: 14px 0;
            }
        }
    }

    .menu-icon {
        width: 48px;
        height: 48px;
        padding: 14px 0;

        img {
            width: 20px;
        }
    }

    .mobile-menu {
        padding-left: 30px;

        .mobile-i18n {
            color: #fff;
            padding-bottom: 30px;

            a {
                padding: 2px 12px;
            }
        }
    }
}
</style>