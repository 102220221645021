<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.LAB_VIEW"/>
    <div class="lab-wrapper">
        <PageBanner>{{ resources.COMMON.$MENU.LAB }}</PageBanner>
        <CenterLayout>
            <div class="page-header">
                <h1 class="typo_head3"
                    v-html="resources.LAB.LAB_TITLE"></h1>
            </div>
        </CenterLayout>
        <CenterLayout>
            <LabList/>
        </CenterLayout>
    </div>
    <GlobalFooter/>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import PageBanner from '@/features/components/PageBanner.vue';
import CenterLayout from '@/features/components/CenterLayout.vue';
import LabList from '@/features/components/lab/LabList.vue';

export default {
    name: 'LabView',
    components: {LabList, CenterLayout, PageBanner, GlobalHeader, GlobalFooter},
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.lab-wrapper {
    padding-top: 88px;

    .page-header {
        width: 100%;
        padding-top: 120px;
        padding-bottom: 40px;

        h1 {
            color: #ffffff;
            font-weight: 500;
            word-break: keep-all;
        }
    }
}

@include tablet {
    .lab-wrapper {
        .page-header {
            padding-top: 60px;
            padding-bottom: 20px;
        }
    }
}

@include mobile {
    .lab-wrapper {
        padding-top: 48px;

        .page-header {
        }
    }
}

</style>