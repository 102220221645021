<template>
    <ul class="work-wrapper">
        <li v-for="work in workList"
            :key="work.TITLE"
            @click.prevent="goToWork(work.$KEY)">
            <div class="work-image">
                <img :src="$filters.getResourcePath(work.IMAGE)"
                     :alt="work.TITLE">
            </div>
            <button class="typo_caption">{{ resources.COMMON.VIEW_MORE }}</button>
            <h2 class="typo_head3"
                v-html="work.TITLE"></h2>
        </li>
    </ul>
</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';
import {useRouter} from 'vue-router';
import {ROUTE_NAMES} from '@/router';

export default {
    name: 'HomeWorkList',
    setup() {
        const store = useStore();
        const router = useRouter();

        const resources = computed(() => store.getters.resources);

        const workList = computed(() => resources.value.HOME.WORK_LIST);

        function goToWork(key) {
            router.push({name: ROUTE_NAMES.WORKS_DETAIL_VIEW, params: {workId: key}});
        }

        return {
            resources,
            workList,
            goToWork,
        };
    },
};
</script>

<style lang="scss" scoped>
.work-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 296px);
    max-height: 800px;
    display: flex;
    justify-content: space-between;
    gap: 4px;

    li {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        cursor: pointer;
        text-align: center;

        .work-image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h2 {
            font-weight: bold;
        }

        &:hover {
            img {
                opacity: 0.9;
            }

            h2 {
                text-decoration: underline;
            }
        }

        button {
            color: #ffffff;
            font-weight: bold;
            display: inline-block;
            margin-top: 16px;
            margin-bottom: 32px;
            padding: 8px 12px;
            border: solid 0.8px rgba(255, 255, 255, 0.35);
            background-color: rgba(255, 255, 255, 0.15);
            cursor: pointer;
        }
    }
}

@include tablet {
    .work-wrapper {
        height: 500px;

        li {
            button {
                padding: 6px 18px;
            }
        }
    }
}

@include mobile {
    .work-wrapper {
        height: auto;
        max-height: none;
        flex-wrap: wrap;
        gap: 2px;

        li {
            flex-basis: 40%;
            padding-top: 240px;
        }
    }
}
</style>