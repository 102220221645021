<template>
    <CenterLayout class="sub-menu-wrapper">
        <ul class="sub-menu">
            <li v-for="menu in menuList"
                :key="menu.id"
                :class="{'on': menu.id === subId}">
                <router-link :to="{name: menu.routeName, params: menu.params}">
                    {{ menu.name }}
                </router-link>
            </li>
        </ul>
    </CenterLayout>
</template>

<script>
import CenterLayout from '@/features/components/CenterLayout.vue';

export default {
    name: 'GlobalSubMenu',
    components: {CenterLayout},
    props: {
        menuList: {
            type: Array,
            default: () => [],
        },
        subId: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.sub-menu-wrapper {
    position: fixed !important;
    left: 0;
    right: 0;
    top: 88px;
    background-color: rgba(255, 255, 255, 0.05);

    ul.sub-menu {
        display: flex;
        justify-content: flex-end;
        padding-right: 7%;
        gap: 30px;

        li {
            a {
                display: inline-block;
                color: #aaafb9;
                padding: 10px 0 13px 0;
            }

            &:hover, &.on {
                a {
                    color: #ffffff;
                    text-shadow: .25px 0 .1px, -.25px 0 .1px;
                }
            }
        }
    }
}
</style>