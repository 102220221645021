<template>
    <div class="wrapper"
         :class="[device]">
        <router-view/>
    </div>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import {APPLICATION_SET_DEVICE} from '@/store/modules/application.js';
import useWindowEvent from '@/features/compositions/useWindowEvent.js';

export default {
    name: 'App',
    setup() {
        const store = useStore();
        useWindowEvent('resize', setDevice);

        const device = computed(() => store.state.application.device);

        function setDevice() {
            store.dispatch(APPLICATION_SET_DEVICE, window.innerWidth);
        }

        return {
            device,
        };
    },
};
</script>