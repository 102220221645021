<template>
    <CenterLayout>
        <div class="slide-wrapper">
            <div ref="slideEl"
                 class="slider">
                <div class="swiper-wrapper">
                    <div v-for="resource in aboutImageList"
                         :key="resource"
                         class="swiper-slide">
                        <img :src="$filters.getResourcePath(resource)"
                             :alt="resource">
                    </div>
                </div>
            </div>
        </div>
        <template #left>
            <div class="arrow arrow--prev"></div>
        </template>
        <template #right>
            <div class="arrow arrow--right"></div>
        </template>
    </CenterLayout>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import Swiper, {Navigation} from 'swiper';
import CenterLayout from '@/features/components/CenterLayout.vue';

export default {
    name: 'AboutSlide',
    components: {CenterLayout},
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        const aboutImageList = computed(() => resources.value.ABOUT.ABOUT_IMAGE_LIST);

        const slideEl = ref(null);
        onMounted(() => {
            const swiper = new Swiper(slideEl.value, {
                modules: [Navigation],
                navigation: {
                    prevEl: '.arrow--prev',
                    nextEl: '.arrow--right',
                },
            });
        });

        return {
            resources,
            aboutImageList,
            slideEl,
        };
    },
};
</script>

<style lang="scss" scoped>
.slide-wrapper {
    padding: 120px 0;

    .slider {
        overflow: hidden;
    }
}

.swiper-wrapper {
    width: 200px;
}

.swiper-slide {
    img {
        width: 100%;
        object-fit: cover;
    }
}

.arrow {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &.swiper-button-disabled {
        display: none;
    }
}

.arrow--prev {
    right: 20%;
    background-image: url("~@/assets/imgs/icn-left.svg");
}

.arrow--right {
    left: 20%;
    background-image: url("~@/assets/imgs/icn-right.svg");
}

@include tablet {
    .slide-wrapper {
        padding: 60px 0;
    }

    .arrow {
        background-color: rgba(0, 0, 0, 0.8);
        width: 64px;
        height: 64px;
        margin-top: -32px;
        opacity: 1;
        border-radius: 100px;
        z-index: 97;
    }

    .arrow--prev {
        right: -96px
    }

    .arrow--right {
        left: -96px;
    }
}

@include mobile {
    .slide-wrapper {
        padding: 40px 0;
    }

    .arrow {
        width: 48px;
        height: 48px;
        margin-top: -24px;
    }

    .arrow--prev {
        right: -64px
    }

    .arrow--right {
        left: -64px;
    }
}
</style>