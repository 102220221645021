import {createRouter, createWebHistory} from 'vue-router';
import {getResources} from '@/router/hooks.js';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import RecruitView from '@/views/RecruitView.vue';
import ContactView from '@/views/ContactView.vue';
import BoardView from '@/views/BoardView.vue';
import WorksView from '@/views/WorksView.vue';
import LabView from '@/views/LabView.vue';
import BoardDetailView from '@/views/BoardDetailView.vue';

export const ROUTE_NAMES = {
    HOME_VIEW: 'HOME_VIEW',
    ABOUT_VIEW: 'ABOUT_VIEW',
    WORKS_VIEW: 'WORKS_VIEW',
    WORKS_DETAIL_VIEW: 'WORKS_DETAIL_VIEW',
    LAB_VIEW: 'LAB_VIEW',
    BOARD_VIEW: 'BOARD_VIEW',
    BOARD_DETAIL_VIEW: 'BOARD_DETAIL_VIEW',
    RECRUIT_VIEW: 'RECRUIT_VIEW',
    CONTACT_VIEW: 'CONTACT_VIEW',
};

const routes = [
    {
        path: '/',
        name: ROUTE_NAMES.HOME_VIEW,
        component: HomeView,
    },
    {
        path: '/about',
        name: ROUTE_NAMES.ABOUT_VIEW,
        component: AboutView,
    },
    {
        path: '/works',
        name: ROUTE_NAMES.WORKS_VIEW,
        redirect: {name: ROUTE_NAMES.WORKS_DETAIL_VIEW, params: {workId: 'VR'}},
    },
    {
        path: '/works/:workId',
        name: ROUTE_NAMES.WORKS_DETAIL_VIEW,
        component: WorksView,
        props: route => ({...route.query, ...route.params}),
    },
    {
        path: '/lab',
        name: ROUTE_NAMES.LAB_VIEW,
        component: LabView,
    },
    {
        path: '/board',
        name: ROUTE_NAMES.BOARD_VIEW,
        component: BoardView,
    },
    {
        path: '/board/:boardIndex',
        name: ROUTE_NAMES.BOARD_DETAIL_VIEW,
        component: BoardDetailView,
        props: route => ({...route.query, ...route.params}),
    },
    {
        path: '/recruit',
        name: ROUTE_NAMES.RECRUIT_VIEW,
        component: RecruitView,
    },
    {
        path: '/contact',
        name: ROUTE_NAMES.CONTACT_VIEW,
        component: ContactView,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: ROUTE_NAMES.HOME_VIEW,
        },
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(getResources);

export default router;
