<template>
    <footer>
        <p class="typo_caption"
           v-html="resources.FOOTER.INFO"></p>
        <p class="copyright typo_caption"
           v-html="resources.FOOTER.COPYRIGHT"></p>
    </footer>
</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
    name: 'GlobalFooter',
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
footer {
    position: relative;
    width: 100%;
    height: 108px;
    color: #fff;
    background-color: #222;
    padding: 32px 0 32px 80px;

    p {
        color: #e9ebee;
    }

    .copyright {
        font-weight: bold;
        margin-top: 4px;
    }
}

@include mobile {
    footer {
        padding: 20px;
        height: auto;
    }
}
</style>