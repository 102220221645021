<template>
    <ul class="lab-list-wrapper">
        <li v-for="lab in labList"
            :key="lab.TITLE">
            <h2 class="typo_head2"
                v-html="lab.TITLE"></h2>
            <p class="typo_body2"
               v-html="lab.DESCRIPTION"></p>
            <img :src="$filters.getResourcePath(lab.THUMBNAIL_IMAGE)"
                 :alt="lab.TITLE">
        </li>
    </ul>
</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
    name: 'LabList',
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        const labList = computed(() => resources.value.LAB.LAB_LIST);

        return {
            labList,
        };
    },
};
</script>

<style lang="scss" scoped>
.lab-list-wrapper {
    margin-bottom: 320px;

    li {
        margin-top: 120px;

        h2 {
            color: #ffffff;
            font-weight: 500;
        }

        p {
            margin-top: 8px;
            color: #e9ebee;
        }

        img {
            width: 100%;
            margin-top: 24px;
        }
    }
}

@include tablet {
    .lab-list-wrapper {
        margin-bottom: 160px;

        li {
            margin-top: 60px;
        }
    }
}

@include mobile {
    .lab-list-wrapper {
        margin-bottom: 80px;

        li {
            margin-top: 60px;

            img {
                margin-top: 12px;
            }
        }
    }
}
</style>