<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.BOARD_VIEW"/>
    <div class="board-wrapper">
        <PageBanner>{{ resources.COMMON.$MENU.BOARD }}</PageBanner>
        <CenterLayout class="page-header">
            <BoardList/>
        </CenterLayout>
    </div>
    <GlobalFooter/>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import PageBanner from '@/features/components/PageBanner.vue';
import CenterLayout from '@/features/components/CenterLayout.vue';
import BoardList from '@/features/components/BoardList.vue';

export default {
    name: 'BoardView',
    components: {BoardList, CenterLayout, PageBanner, GlobalHeader, GlobalFooter},
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.board-wrapper {
    padding-top: 88px;
    min-height: 100%;

    .page-header {
        width: 100%;
        padding: 120px 0;
    }
}

@include tablet {
    .board-wrapper {
        .page-header {
            padding: 60px 0;
        }
    }
}

@include mobile {
    .board-wrapper {
        padding-top: 48px;

        .page-header {
            padding: 40px 0;
        }
    }
}

</style>