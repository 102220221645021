<template>
    <div class="left-typo-wrapper">
        <p class="typo_caption"
           v-html="resources.HOME.INTRO_SIDE_MESSAGE"></p>
    </div>
</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
    name: 'LeftTypo',
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.left-typo-wrapper {
    position: absolute;
    top: 50%;
    left: 80px;
    z-index: 90;

    p {
        color: #e9ebee;
    }
}

@include tablet {
    .left-typo-wrapper {
        display: none;
    }
}
</style>