<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.BOARD_DETAIL_VIEW"/>
    <div class="board-wrapper">
        <BoardDetail :boardIndex="boardIndex"/>
    </div>
    <GlobalFooter/>
</template>

<script>
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import BoardDetail from '@/features/components/board/BoardDetail.vue';

export default {
    name: 'BoardDetailView',
    components: {BoardDetail, GlobalHeader, GlobalFooter},
    props: {
        boardIndex: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.board-wrapper {
    padding-top: 328px;
    min-height: 100%;
    background-color: #181d1f;
}

@include tablet {
    .board-wrapper {
        padding-top: 164px;
    }
}

@include mobile {
    .board-wrapper {
        padding-top: 82px;
    }
}

</style>