<template>
    <div class="nav-wrapper">
        <p class="typo_caption">0{{ currentPage }}</p>
        <div class="slider">
            <div class="item"
                 :style="{'top': `${(currentPage - 1) * 17}px`}"></div>
        </div>
        <p class="typo_caption">04</p>
    </div>
</template>

<script>
import {computed} from 'vue';

export default {
    name: 'ScrollNav',
    props: {
        page: {
            type: Number,
            default: 0,
        },
    },
    setup(props) {
        const currentPage = computed(() => {
            if (props.page > 3) {
                return 4;
            } else {
                return props.page + 1;
            }
        });

        return {
            currentPage,
        };
    },
};
</script>

<style lang="scss" scoped>
.nav-wrapper {
    position: fixed;
    top: 50%;
    right: 80px;
    z-index: 90;
    width: 14px;

    .slider {
        position: relative;
        left: 7px;
        width: 1px;
        height: calc(17px * 4);
        margin: 8px 0;
        background-color: #ddd;

        .item {
            position: relative;
            left: -1px;
            width: 3px;
            height: 17px;
            background-color: #fff;
            -webkit-transition: .5s;
            transition: .5s;
        }
    }
}

@include tablet {
    .nav-wrapper {
        display: none;
    }
}
</style>