<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.WORKS_VIEW"
                  :subId="workId"/>
    <div class="works-wrapper">
        <PageBanner>{{ resources.COMMON.$MENU.WORKS }}</PageBanner>
        <SubNav :menuList="workMenuList"
                :subId="workId"/>

        <CenterLayout>
            <div class="page-header">
                <h1 class="typo_head1"
                    v-html="work.TITLE"></h1>
                <p class="typo_body3"
                   v-html="work.DESCRIPTION"></p>
            </div>
        </CenterLayout>

        <CenterLayout class="arts">
            <ArtSlide v-for="art in work.ART_LIST"
                      :key="art.NAME"
                      :art="art"></ArtSlide>
        </CenterLayout>
    </div>
    <GlobalFooter/>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import PageBanner from '@/features/components/PageBanner.vue';
import SubNav from '@/features/components/SubNav.vue';
import CenterLayout from '@/features/components/CenterLayout.vue';
import ArtSlide from '@/features/components/works/ArtSlide.vue';

export default {
    name: 'WorksView',
    components: {ArtSlide, CenterLayout, SubNav, PageBanner, GlobalHeader, GlobalFooter},
    props: {
        workId: {
            type: String,
        },
    },
    setup(props) {
        const store = useStore();

        const resources = computed(() => store.getters.resources);

        const workMenuList = computed(() => resources.value.WORKS.WORK_LIST.map(work => ({
            id: work.$KEY,
            name: work.NAME,
            params: {
                workId: work.$KEY,
            },
        })));

        const work = computed(() => resources.value.WORKS.WORK_LIST.find(work => work.$KEY === props.workId));

        return {
            resources,
            workMenuList,
            work,
        };
    },
};
</script>

<style lang="scss" scoped>
.works-wrapper {
    padding-top: 88px;
    min-height: 100%;

    .page-header {
        width: 100%;
        padding-top: 100px;
        padding-bottom: 120px;
        color: #ffffff;

        p {
            margin-top: 16px;
            color: #e9ebee;
        }
    }

    .arts {
        overflow: hidden;

        &:last-child {
            margin-bottom: 320px;
        }
    }
}

@include tablet {
    .works-wrapper {
        .page-header {
            padding-top: 50px;
            padding-bottom: 60px;
        }

        .arts {
            &:last-child {
                margin-bottom: 160px;
            }
        }
    }
}

@include mobile {
    .works-wrapper {
        padding-top: 48px;

        .page-header {
            padding-top: 40px;
            padding-bottom: 60px;
        }

        .arts {
            &:last-child {
                margin-bottom: 80px;
            }
        }
    }
}

</style>