<template>
    <div class="banner-wrapper"
         :style="{'background-image': `url(${$filters.getResourcePath(resources.COMMON.PAGE_HEADER)})`}">
        <h1 class="typo_display3">
            <slot></slot>
        </h1>
    </div>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
    name: 'PageBanner',
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.banner-wrapper {
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    h1 {
        color: #ffffff;
        font-weight: 800;
    }
}

@include tablet {
    .banner-wrapper {
        padding: 60px 0;
    }
}

@include mobile {
    .banner-wrapper {
        padding: 40px 0;
    }
}
</style>