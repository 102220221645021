<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.HOME_VIEW"
                  :class="{
                      'top': page === 0,
                      'scroll': page !== 0,
                      'show': !isScrollDown || page === 0,
                      'hide': isScrollDown && page !== 0,
                  }"/>
    <div class="home-wrapper">
        <ScrollNav :page="page"/>
        <div class="contents"
             ref="pageEl">
            <section class="fullscreen center-typo">
                <div class="inner">
                    <h1 class="typo_display3"
                        v-html="resources.HOME.INTRO_TITLE"></h1>
                    <p class="typo_head2"
                       v-html="resources.HOME.INTRO_SUB_TITLE"></p>
                </div>
                <FullscreenVideo/>
                <ScrollDownArrow/>
                <LeftTypo/>
            </section>
            <section class="background2"
                     :style="{'background-image': `url(${$filters.getResourcePath(resources.HOME.INTRO2_IMAGE)})`}">
                <CenterLayout>
                    <div class="left-top-typo">
                        <h1 class="typo_head1"
                            v-html="resources.HOME.INTRO2_TITLE"></h1>
                        <p class="typo_body3"
                           v-html="resources.HOME.INTRO2_SUB_TITLE"></p>
                    </div>
                </CenterLayout>
            </section>
            <section>
                <CenterLayout class="page-header">
                    <div class="page-contents">
                        <h1 class="typo_head1">
                          <router-link :to="{name: $ROUTE_NAMES.WORKS_VIEW}">{{ resources.COMMON.$MENU.WORKS }} ≫</router-link>
                        </h1>
                        <p class="typo_body3"
                           v-html="resources.HOME.WORK_SUB_TITLE"></p>
                    </div>
                </CenterLayout>
                <HomeWorkList/>
            </section>
            <section>
                <CenterLayout class="page-header">
                    <div class="page-contents">
                        <h1 class="typo_head1">
                          <router-link :to="{name: $ROUTE_NAMES.BOARD_VIEW}">{{ resources.COMMON.$MENU.BOARD }} ≫</router-link>
                        </h1>
                    </div>
                </CenterLayout>
                <CenterLayout>
                    <BoardList :max="2"/>
                </CenterLayout>
            </section>
            <GlobalFooter/>
        </div>
    </div>
</template>

<script>
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import FullscreenVideo from '@/features/components/FullscreenVideo.vue';
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import ScrollDownArrow from '@/features/components/home/ScrollDownArrow.vue';
import ScrollNav from '@/features/components/home/ScrollNav.vue';
import HomeWorkList from '@/features/components/home/HomeWorkList.vue';
import BoardList from '@/features/components/BoardList.vue';
import CenterLayout from '@/features/components/CenterLayout.vue';
import useWindowEvent from '@/features/compositions/useWindowEvent.js';
import LeftTypo from '@/features/components/home/LeftTypo.vue';

const LAST_PAGE = 4;

export default {
    name: 'HomeView',
    components: {
        LeftTypo,
        CenterLayout,
        BoardList, HomeWorkList, ScrollNav, ScrollDownArrow, GlobalFooter, FullscreenVideo, GlobalHeader,
    },
    setup() {
        const store = useStore();

        const resources = computed(() => store.getters.resources);

        useWindowEvent('wheel', customWheelEvent, {passive: false});
        useWindowEvent('resize', setPageHeight);

        const page = ref(0);
        const pageEl = ref(null);
        const pageHeight = ref(window.innerHeight);
        const device = computed(() => store.state.application.device);

        const isScrollDown = ref(false);
        let isScrolling = false;

        function customWheelEvent(e) {
            if (device.value === 'pc') {
                e.preventDefault();

                if (isScrolling) {
                    return;
                }
                isScrolling = true;

                if (e.deltaY > 0) {
                    // down
                    isScrollDown.value = true;
                    if (page.value < LAST_PAGE) {
                        pageTo(page.value + 1);
                    }
                } else if (e.deltaY < 0) {
                    // up
                    isScrollDown.value = false;
                    if (page.value > 0) {
                        pageTo(page.value - 1);
                    }
                }

                setTimeout(() => isScrolling = false, 500);
            }
        }

        function setPageHeight() {
            pageHeight.value = window.innerHeight;
            if (device.value === 'pc') {
                pageTo(page.value);
            } else {
                pageTo(0);
            }
        }

        function pageTo(targetPage) {
            if (pageEl.value) {
                page.value = targetPage;
                if (page.value === LAST_PAGE) {
                    pageEl.value.style.top = `${-1 * pageHeight.value * (LAST_PAGE - 1) - 108}px`;
                } else {
                    pageEl.value.style.top = `${-1 * pageHeight.value * page.value}px`;
                }
            }

        }

        return {
            resources,
            page,
            pageEl,
            isScrollDown,
        };
    },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
    position: relative;
    color: white;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .contents {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: .5s;
        transition: .5s;

        section {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &.center-typo {
                display: flex;
                justify-content: center;
                align-items: center;

                .inner {
                    width: 80%;
                    text-align: center;

                    h1 {
                        font-weight: 800;
                    }

                    p {
                        margin-top: 16px;
                        font-weight: 500;
                    }
                }
            }

            .left-top-typo {
                margin-top: 160px;

                h1 {
                    font-weight: bold;
                }

                p {
                    margin-top: 16px;
                    color: #e9ebee;
                }
            }

            .page-header {
                width: 100%;
                height: 296px;
                padding: 120px 0;

                .page-contents {
                    height: 56px;
                    flex-basis: 1280px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h1 {
                        display: inline-block;
                        font-weight: 800;
                    }

                    p {
                        color: #e9ebee;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.background2 {
    background-size: cover;
    background-position: center;
}

@include tablet {
    .home-wrapper {
        overflow: auto;
        height: auto;

        .contents {
            position: relative;
            top: 0;
            height: auto;

            section {
                min-height: 60vh;
                height: auto;

                &.fullscreen {
                    height: 100vh;
                }

                .left-top-typo {
                    margin-top: 60px;
                }

                .page-header {
                    height: auto;
                    padding: 90px 60px 60px;

                    .page-contents {
                        height: auto;
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    p {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

@include mobile {
    .home-wrapper {
        .contents {
            section {
                .page-header {
                    height: auto;
                    padding: 90px 30px 60px;
                }
            }
        }
    }
}

.typo_head1 a:hover {
  text-decoration: underline;
}
</style>