import {onMounted, onUnmounted} from 'vue';

export default function (eventType, eventHandler, options) {

    onMounted(() => {
        window.addEventListener(eventType, eventHandler, options);
    });

    onUnmounted(() => {
        window.removeEventListener(eventType, eventHandler, options);
    });
}