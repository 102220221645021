<template>
    <ul class="board-list">
        <li v-for="(board, index) in boardList"
            :key="index"
            @click.prevent="goToBoardDetail(index)">
            <template v-if="board.TITLE">
                <div class="board-image">
                    <img :src="$filters.getResourcePath(board.THUMBNAIL_IMAGE)"
                         :alt="board.TITLE">
                    <div class="dimmed">
                        <button>{{ resources.COMMON.VIEW_MORE }}</button>
                    </div>
                </div>
                <h2 class="typo_head2">{{ board.TITLE }}</h2>
            </template>
        </li>
    </ul>
</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';
import {useRouter} from 'vue-router';
import {APPLICATION_SET_FROM_SELF} from '@/store/modules/application.js';
import {ROUTE_NAMES} from '@/router';

export default {
    name: 'BoardList',
    props: {
        max: {
            type: Number,
            default: 1000,
        },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const resources = computed(() => store.getters.resources);

        const boardList = computed(() => {
            const evenList = [...resources.value.BOARD.BOARD_LIST, {TITLE: null}];
            const even = (props.max % 2 === 0) ? props.max : props.max + 1;

            return evenList.slice(0, even);
        });

        // BOARD format
        // {
        //     "TITLE": "테스트 기사 제목입니다.",
        //     "DATE": "2022.02.04",
        //     "THUMBNAIL_IMAGE": "test.png",
        //     "DETAIL_IMAGE": "test_long.png"
        //     "LINK": null
        // },

        function goToBoardDetail(boardIndex) {
            if (boardList.value[boardIndex].LINK) {
                window.open(boardList.value[boardIndex].LINK); // 새탭
            } else {
                store.dispatch(APPLICATION_SET_FROM_SELF, true);
                router.push({name: ROUTE_NAMES.BOARD_DETAIL_VIEW, params: {boardIndex}});
            }
        }

        return {
            resources,
            boardList,
            goToBoardDetail,
        };
    },
};
</script>

<style lang="scss" scoped>
ul.board-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 64px;

    li {
        width: 40%;
        max-width: 600px;
        flex-grow: 1;
        cursor: pointer;

        .board-image {
            position: relative;
            display: flex;
            width: 100%;

            &::after {
                position: relative;
                content: "";
                padding-top: 70%;
            }

            img {
                width: 100%;
                object-fit: cover;
                background-color: #222222;
            }

            div.dimmed {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.2);

                button {
                    color: #ffffff;
                    font-weight: bold;
                    display: inline-block;
                    height: 38px;
                    padding: 8px 24px;
                    border: solid 0.8px rgba(255, 255, 255, 0.35);
                    background-color: rgba(255, 255, 255, 0.15);
                    cursor: pointer;
                }
            }
        }

        h2 {
            color: #fff;
            margin-top: 16px;
        }

        &:hover {
            div.dimmed {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            h2 {
                text-decoration: underline;
            }
        }
    }
}

@include tablet {
    ul.board-list {
        margin-bottom: 64px;
        gap: 32px;

        li {
            flex-basis: 300px;

            h2 {
                margin-top: 12px;
            }
        }
    }
}
</style>