<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.ABOUT_VIEW"/>
    <div class="about-wrapper">
        <PageBanner>{{ resources.COMMON.$MENU.ABOUT }}</PageBanner>
        <AboutSlide/>
        <CenterLayout class="page-header">
            <div class="page-contents">
                <h1 class="typo_display2"
                    v-html="resources.ABOUT.ABOUT_TITLE"></h1>
                <p class="typo_body1"
                   v-html="resources.ABOUT.ABOUT_INFO"></p>
            </div>
        </CenterLayout>
        <CenterLayout class="about-image-wrapper">
            <img :src="$filters.getResourcePath(resources.ABOUT.GRAPH_IMAGE)"
                 alt="about_graph">
            <p class="typo_body1"
               v-html="resources.ABOUT.GRAPH_INFO"></p>
        </CenterLayout>
    </div>
    <GlobalFooter/>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import PageBanner from '@/features/components/PageBanner.vue';
import CenterLayout from '@/features/components/CenterLayout.vue';
import AboutSlide from '@/features/components/about/AboutSlide.vue';

export default {
    name: 'AboutView',
    components: {AboutSlide, CenterLayout, PageBanner, GlobalFooter, GlobalHeader},
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.about-wrapper {
    padding-top: 88px;

    .page-header {
        width: 100%;
        padding: 120px 0;

        .page-contents {
            h1 {
                color: #fff;
                font-weight: 800;
            }

            p {
                margin-top: 64px;
                color: #e9ebee;
                font-weight: 500;
                word-break: keep-all;
            }
        }
    }

    .about-image-wrapper {
        padding: 120px 0;
        text-align: center;

        img {
            width: 100%;
            max-width: 723px;
            object-fit: cover;
        }

        p {
            margin-top: 64px;
            margin-bottom: 320px;
            color: #e9ebee;
            text-align: left;
        }
    }
}

@include tablet {
    .about-wrapper {
        .page-header {
            padding: 60px 0;

            .page-contents {
                p {
                    margin-top: 32px;
                }
            }
        }

        .about-image-wrapper {
            padding: 60px 0;

            p {
                margin-top: 32px;
                margin-bottom: 160px;
            }
        }
    }
}

@include mobile {
    .about-wrapper {
        padding-top: 48px;

        .page-header {
            padding: 40px 0;
        }

        .about-image-wrapper {
            padding: 40px 0;

            p {
                margin-bottom: 80px;
            }
        }
    }
}
</style>