import axios from 'axios';

export const APPLICATION_SET_DEVICE = 'APPLICATION_SET_DEVICE';
export const APPLICATION_SET_RESOURCES = 'APPLICATION_SET_RESOURCES';
export const APPLICATION_SET_FROM_SELF = 'APPLICATION_SET_FROM_SELF';
export const APPLICATION_SET_LANG = 'APPLICATION_SET_LANG';

const SET_DEVICE = 'SET_DEVICE';
const SET_RESOURCES = 'SET_RESOURCES';
const SET_FROM_SELF = 'SET_FROM_SELF';
const SET_LANG = 'SET_LANG';

const getInitState = () => ({
    device: 'pc',
    resources: {
        KR: null,
        EN: null,
    },
    isFromSelf: false,  // 라우팅이 내부에서 일어났는지 플래그용
    lang: null,
});

const getters = {
    resources: state => state.lang ? state.resources[state.lang] : null,
    lang: state => state.lang,
};

const actions = {
    [APPLICATION_SET_DEVICE]: async ({commit}, width) => {
        commit(SET_DEVICE, (width > +process.env.VUE_APP_MOBILE_DEVICE_THRESHOLD) ? 'pc' : 'mobile');
    },
    [APPLICATION_SET_RESOURCES]: async ({commit}) => {
        let resources = {
            KR: null,
            EN: null,
        };
        if (process.env.VUE_APP_PROFILE === 'local') {
            resources.KR = require('../../../SETTING_KR.json');
            resources.EN = require('../../../SETTING_EN.json');
        } else {
            const {data: kr} = await axios.get(`${process.env.VUE_APP_S3_PREFIX}/SETTING_KR.json`);
            const {data: en} = await axios.get(`${process.env.VUE_APP_S3_PREFIX}/SETTING_EN.json`);
            resources.KR = kr;
            resources.EN = en;
        }

        commit(SET_RESOURCES, resources);
    },
    [APPLICATION_SET_FROM_SELF]: async ({commit}, isFromSelf) => {
        commit(SET_FROM_SELF, isFromSelf);
    },
    [APPLICATION_SET_LANG]: async ({commit}, lang) => {
        commit(SET_LANG, lang);
    },
};

const mutations = {
    [SET_DEVICE]: (state, device) => {
        state.device = device;
    },
    [SET_RESOURCES]: (state, resources) => {
        state.resources = resources;
    },
    [SET_FROM_SELF]: (state, isFromSelf) => {
        state.isFromSelf = isFromSelf;
    },
    [SET_LANG]: (state, lang) => {
        state.lang = lang;
    },
};

export default {
    state: getInitState(),
    getters,
    actions,
    mutations,
};