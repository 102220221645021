<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.RECRUIT_VIEW"/>
    <div class="recruit-wrapper">
        <PageBanner>{{ resources.COMMON.$MENU.RECRUIT }}</PageBanner>
        <CenterLayout>
            <div class="recruit-image-wrapper">
                <img :src="$filters.getResourcePath(resources.RECRUIT.MAIN_IMAGE)"
                     alt="recruit">
            </div>
        </CenterLayout>
    </div>
    <GlobalFooter/>
</template>

<script>
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import PageBanner from '@/features/components/PageBanner.vue';
import CenterLayout from '@/features/components/CenterLayout.vue';
import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
    name: 'RecruitView',
    components: {CenterLayout, PageBanner, GlobalFooter, GlobalHeader},
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.recruit-wrapper {
    padding-top: 88px;

    .recruit-image-wrapper {
        padding-top: 120px;
        padding-bottom: 320px;
        text-align: center;

        img {
            max-width: 100%;
            display: inline-block;
        }
    }
}

@include tablet {
    .recruit-wrapper {
        .recruit-image-wrapper {
            padding-top: 60px;
            padding-bottom: 160px;
        }
    }
}

@include mobile {
    .recruit-wrapper {
        padding-top: 48px;

        .recruit-image-wrapper {
            padding-top: 40px;
            padding-bottom: 120px;
        }
    }
}
</style>