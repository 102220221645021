<template>
    <div class="arrow-wrapper">
        <img src="@/assets/imgs/icn-scroll-down.svg" alt="">
    </div>
</template>

<script>
export default {
    name: 'ScrollDownArrow',
};
</script>

<style lang="scss" scoped>
.arrow-wrapper {
    position: absolute;
    animation-duration: 1s;
    animation-name: upDown;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    img {
        display: block;
        width: 16px;
        margin: 0 auto;
    }
}

@keyframes upDown {
    0% {
        bottom: 64px;
    }
    90% {
        bottom: 58px;
    }
    100% {
        bottom: 64px;
    }
}
</style>