<template>
    <div class="video-wrapper">
        <video :src="$filters.getResourcePath(resources.HOME.INTRO_VIDEO)"
               id="background_video"
               preload="auto"
               autoplay="autoplay"
               loop="loop"
               muted="muted"
               volume="0"></video>
    </div>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
    name: 'FullscreenVideo',
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    z-index: -1;

    video {
        height: 100%;
        width: 177.77777778vh;
        min-width: 100%;
        min-height: 56.25vw;
    }
}
</style>