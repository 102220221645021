<template>
    <nav>
        <CenterLayout>
            <ul>
                <li v-for="menu in menuList"
                    :key="menu.name"
                    :class="{'on': menu.id === subId}">
                    <router-link :to="{params: menu.params}"
                                 replace>{{ menu.name }}
                    </router-link>
                </li>
            </ul>
        </CenterLayout>
    </nav>
</template>

<script>
import CenterLayout from '@/features/components/CenterLayout.vue';

export default {
    name: 'SubNav',
    components: {CenterLayout},
    props: {
        menuList: {
            type: Array,
            default: () => [],
        },
        subId: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
nav {
    border-bottom: 1px solid #ffffff26;

    ul {
        display: flex;

        li {
            flex-grow: 1;

            a {
                display: inline-block;
                width: 100%;
                padding: 16px 0;
                text-align: center;
                color: #e9ebee;
            }

            &.on, &:hover {
                a {
                    background-color: rgba(255, 255, 255, 0.12);
                    color: #ffffff;
                    text-shadow: .25px 0 .1px, -.25px 0 .1px;
                }
            }
        }
    }
}
</style>