<template>
    <CenterLayout class="detail-wrapper">
        <h1 class="typo_head2">{{ board.TITLE }}</h1>
        <p class="typo_body1">{{ board.DATE }}</p>
        <hr/>
        <div class="contents">
            <img :src="$filters.getResourcePath(board.DETAIL_IMAGE)"
                 :alt="board.TITLE">
        </div>

        <button @click.prevent="goToBoardList">{{ resources.COMMON.LIST_BUTTON }}</button>
    </CenterLayout>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import CenterLayout from '@/features/components/CenterLayout.vue';
import {ROUTE_NAMES} from '@/router';

export default {
    name: 'BoardDetail',
    components: {CenterLayout},
    props: {
        boardIndex: {
            type: String,
        },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        const resources = computed(() => store.getters.resources);

        const board = computed(() => resources.value.BOARD.BOARD_LIST[props.boardIndex ? +props.boardIndex : 0]);

        function goToBoardList() {
            if (store.state.application.isFromSelf) {
                // 내부에서 넘어온 경우
                router.back();
            } else {
                router.push({name: ROUTE_NAMES.BOARD_VIEW});
            }
        }

        return {
            resources,
            board,
            goToBoardList,
        };
    },
};
</script>

<style lang="scss" scoped>
.detail-wrapper {
    h1 {
        color: #ffffff;
        font-weight: bold;
    }

    p {
        margin-top: 16px;
        color: #ffffff;
    }

    hr {
        height: 4px;
        margin-top: 24px;
        background-color: #ffffff;
    }

    .contents {
        margin-top: 64px;
        width: 100%;
        text-align: center;

        img {
            max-width: 100%;
            display: inline-block;
        }
    }

    button {
        display: block;
        color: #ffffff;
        margin: 120px auto;
        padding: 16px 64px;
        border: solid 0.8px rgba(255, 255, 255, 0.35);
        background-color: rgba(255, 255, 255, 0.15);
        cursor: pointer;
    }
}

@include tablet {
    .detail-wrapper {
        button {
            margin: 60px auto;
        }
    }
}

@include mobile {
    .detail-wrapper {
        .contents {
            margin-top: 32px;
        }
    }
}

</style>