<template>
    <div class="layout-wrapper">
        <div class="space">
            <slot name="left"></slot>
        </div>
        <div class="main">
            <slot></slot>
        </div>
        <div class="space">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CenterLayout',
};
</script>

<style lang="scss" scoped>
div.layout-wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    .space {
        position: relative;
        flex-basis: 150px;
        flex-grow: 1;
    }

    .main {
        position: relative;
        flex-basis: 1280px;
    }
}

@include tablet {
    div.layout-wrapper {
        .space {
            flex-basis: 50px;
        }
    }
}
</style>