<template>
    <div class="art-slide-wrapper">
        <div class="info">
            <h2 class="typo_head2">{{ art.NAME }}</h2>
            <button v-if="art.LINK">
                <a :href="art.LINK"
                   target="_blank">
                    {{ resources.COMMON.VIEW_MORE }}
                </a>
            </button>
            <div class="arrow-wrapper">
                <div ref="prevEl"
                     class="arrow arrow--prev"></div>
                <div ref="nextEl"
                     class="arrow arrow--right"></div>
            </div>
        </div>
        <div class="gallery">
            <div class="overview">
                <div ref="slideEl"
                     class="slider">
                    <div class="swiper-wrapper">
                        <div v-for="resource in art.IMAGE_LIST"
                             :key="resource"
                             class="swiper-slide">
                            <img :src="$filters.getResourcePath(resource)"
                                 :alt="resource">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// ART format
// {
//     "NAME": "무엇무엇",
//     "LINK": "https://연결 링크",
//     "IMAGE_LIST": []
// },

import {computed, getCurrentInstance, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import Swiper, {Navigation} from 'swiper';

export default {
    name: 'ArtSlide',
    props: {
        art: {
            type: Object,
        },
    },
    setup() {
        const store = useStore();

        const slideEl = ref(null);
        const prevEl = ref(null);
        const nextEl = ref(null);

        const resources = computed(() => store.getters.resources);

        function goToOutLink(link) {
        }

        onMounted(() => {
            const swiper = new Swiper(slideEl.value, {
                modules: [Navigation],
                navigation: {
                    prevEl: prevEl.value,
                    nextEl: nextEl.value,
                },
                spaceBetween: 8,
                slidesPerView: 'auto',
            });
        });

        return {
            slideEl,
            prevEl,
            nextEl,
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.art-slide-wrapper {
    display: flex;
    gap: 100px;
    margin-bottom: 120px;

    .info {
        flex-basis: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
            color: #fff;
            font-weight: 500;
            word-break: keep-all;
        }

        button {
            color: #ffffff;
            font-weight: bold;
            display: block;
            margin: 32px auto;
            border: solid 0.8px rgba(255, 255, 255, 0.35);
            background-color: rgba(255, 255, 255, 0.15);
            cursor: pointer;

            a {
                display: inline-block;
                padding: 8px 24px;
            }
        }

        .arrow-wrapper {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .arrow {
                width: 32px;
                height: 32px;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;

                &.swiper-button-disabled {
                    opacity: 0.4;
                    cursor: no-drop;
                }
            }

            .arrow--prev {
                background-image: url("~@/assets/imgs/icn-left.svg");
            }

            .arrow--right {
                background-image: url("~@/assets/imgs/icn-right.svg");
            }
        }
    }

    .gallery {
        position: relative;
        height: 260px;
        flex-grow: 1;

        .overview {
            position: absolute;
            height: 100%;
            left: 0;
            right: -100%;
            overflow: hidden;
        }
    }
}

.slider {
    width: 800px;
}

.swiper-slide {
    width: auto;
    height: 260px;

    img {
        height: 100%;
        object-fit: cover;
    }
}

@include tablet {
    .art-slide-wrapper {
        gap: 20px;
        flex-direction: column;
        margin-bottom: 60px;

        .info {
            flex-basis: auto;
            flex-direction: row;

            button {
                position: relative;
                margin: 0;
                border: 0;
                background-color: inherit;

                &::after {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    right: 6px;
                    top: 9px;
                    background-image: url("~@/assets/imgs/icn-right.svg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            .arrow-wrapper {
                display: none;
            }
        }
    }
}

@include mobile {
    .art-slide-wrapper {
        .gallery {
            height: 140px;
        }
    }

    .slider {
        width: 400px;
    }

    .swiper-slide {
        height: 140px;
    }
}
</style>