<template>
    <GlobalHeader :routeName="$ROUTE_NAMES.CONTACT_VIEW"/>
    <div class="contact-wrapper">
        <PageBanner>{{ resources.COMMON.$MENU.CONTACT }}</PageBanner>
        <CenterLayout class="contact-info">
            <div class="contents">
                <dl>
                    <dt class="typo_body3"
                        v-html="resources.CONTACT.EMAIL_TITLE"></dt>
                    <dd class="typo_head2"
                        v-html="resources.CONTACT.EMAIL"></dd>
                </dl>
                <dl>
                    <dt class="typo_body3"
                        v-html="resources.CONTACT.ADDRESS_TITLE"></dt>
                    <dd class="typo_head2"
                        v-html="resources.CONTACT.ADDRESS"></dd>
                </dl>
            </div>
        </CenterLayout>
        <CenterLayout class="map-wrapper">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.587895549383!2d126.82426381531154!3d37.56477197979821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9de443ad4b75%3A0xc9a96753eaf261cd!2z65GQ7IKw642U656c65Oc7YOA7JuMIELrj5k!5e0!3m2!1sko!2skr!4v1643112171054!5m2!1sko!2skr"
                style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </CenterLayout>
    </div>
    <GlobalFooter/>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import GlobalHeader from '@/features/components/GlobalHeader.vue';
import GlobalFooter from '@/features/components/GlobalFooter.vue';
import PageBanner from '@/features/components/PageBanner.vue';
import CenterLayout from '@/features/components/CenterLayout.vue';

export default {
    name: 'ContactView',
    components: {CenterLayout, PageBanner, GlobalFooter, GlobalHeader},
    setup() {
        const store = useStore();
        const resources = computed(() => store.getters.resources);

        return {
            resources,
        };
    },
};
</script>

<style lang="scss" scoped>
.contact-wrapper {
    padding-top: 88px;

    .contact-info {
        padding-top: 120px;
        padding-bottom: 16px;

        .contents {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            dl {
                color: #fff;
                margin-bottom: 64px;
                font-weight: bold;

                dt {
                    color: #666;
                    margin-bottom: 12px;
                }

                dd {
                    font-weight: bold;
                    max-width: 632px;
                    word-break: keep-all;
                }
            }
        }
    }

    .map-wrapper {
        position: relative;
        padding-bottom: 320px;

        iframe {
            width: 100%;
            height: 100%;
        }

        &::after {
            position: relative;
            content: "";
            padding-top: 36%;
        }
    }
}

@include tablet {
    .contact-wrapper {
        .contact-info {
            padding-top: 60px;
            padding-bottom: 8px;
        }

        .map-wrapper {
            padding-bottom: 160px;

            &::after {
                padding-top: 44%;
            }
        }
    }
}

@include mobile {
    .contact-wrapper {
        padding-top: 48px;

        .contact-info {
            padding-top: 40px;
            padding-bottom: 6px;

            .contents {
                flex-flow: column;
                gap: 0;
            }
        }

        .map-wrapper {
            padding-bottom: 80px;

            &::after {
                padding-top: 60%;
            }
        }
    }
}
</style>