import {createApp} from 'vue';
import App from './App.vue';
import router, {ROUTE_NAMES} from './router';
import store from './store';

import 'swiper/swiper-bundle.css';
import '@/assets/scss/reset.scss';
import '@/assets/scss/index.scss';

const app = createApp(App);

app.config.globalProperties.$ROUTE_NAMES = ROUTE_NAMES;
app.config.globalProperties.$filters = {
    getResourcePath: resourcesName => {
        if (resourcesName.indexOf('http') === 0) {
            return resourcesName;
        }

        return `${process.env.VUE_APP_S3_PREFIX}/${resourcesName}`;
    },
};

app.use(store)
   .use(router)
   .mount('#app');