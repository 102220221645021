import store from '@/store';
import {APPLICATION_SET_LANG, APPLICATION_SET_RESOURCES} from '@/store/modules/application.js';

const KR = ['ko', 'ko-kr', 'kr'];

export async function getResources(to, from, next) {
    if (!store.state.application.resources[KR]) {
        await store.dispatch(APPLICATION_SET_RESOURCES);
    }

    if (!store.state.application.lang) {
        const lang = (window.navigator.language || 'ko').toLowerCase();
        if (KR.includes(lang)) {
            await store.dispatch(APPLICATION_SET_LANG, 'KR');
        } else {
            await store.dispatch(APPLICATION_SET_LANG, 'EN');
        }
    }

    next();
}